<template>
    <div class="mask-rule">
        <div class="box-rule">
            <span class="star"></span>
            <h2>我的记录</h2>
            <span class="close-rule" @click="$emit('close')"></span>
            <div class="con">
                <!-- <div class="text" v-html="content">
                </div> -->
                <!-- {{ content }} -->
                <div class="con-title">
                    <div  class="con-time">鱼获时间</div>
                    <div class="con-fishingRod">钓竿</div>
                    <div class="con-num">数量</div>
                    <div class="con-type">鱼类</div>
                    <div class="con-result">是否中奖</div>
                    <div class="con-gift">中奖礼物</div>
                </div>
                <div class="con-con" v-for="(item,index) in content" :key="index">
                    <div class="con-time">{{item.created_at.slice(5,16)}}</div>
                    <div class="con-fishingRod">{{item.lotterygame.name == '捕鱼达人' ? '青竿': item.lotterygame.name.slice(6)}}</div>
                    <div class="con-num">{{item.buy_num}}</div>
                    <img :src=item.lotterygame.icon_all alt="" class="con-type con-type-img">
                    <div class="con-result">{{item.status==1?'中奖':'未中奖'}}</div>
                    <div class="con-gift"> 
                    <img :src="v.image" alt="" v-for="(v,i) in item.prize_info" :key="i">
                     </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "rule",
        props: {
            content: {
                type: Array,
                default: ''
            }
        }
    }

</script>

<style scoped>
.mask-rule .con{
    margin: .6rem .3rem !important;
}
.con-title{
    display: flex;
    justify-content: space-between;
}
.con-con{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* .con-con img{
    width: 1rem;
} */
.con-time{
    width: 2rem;
    /* font-size: .35rem; */
    text-align: center;
}
.con-fishingRod{
    width: 1.5rem;
    text-align: center;
}
.con-num{
    width: 1rem;
    text-align: center;
}
.con-type{
    width: 1.1rem;
    text-align: center;
}
.con-type-img{
    width: .8rem;
}
.con-gift{
    width: 1.8rem;
    text-align: center;
}
.con-gift img{
    width: .8rem;
    /* height: .8rem; */
}
.con-result{
    width: 1.5rem;
    text-align: center;
}

</style>
