<template>
  <div class="wrap">
    <div class="iconBox">
      <img
        src="../../assets/newegg/image/new-return.png"
        alt=""
        class="closeGame"
        @click="close"
      />
      <div class="balance">
        <img
          src="../../assets/catchFish/image/title.png"
          alt=""
          class="balance-img"
        />
      </div>
      <div class="rule">
        <img src="../../assets/catchFish/image/history.png" 
        @click="getMyHistoryHandle"
        alt="" class="historyImg">
        <img
          src="../../assets/catchFish/image/help.png"
          alt=""
          class="helpImg"
          @click="showRule = true"
        />
      </div>
    </div>
    <div class="box">
      <div class="box-item">
        <div>
          <img
            src="../../assets/catchFish/image/fishingRod6.png"
            alt=""
            v-if="fishingRodObj.green_rod"
            @click="fishingRod('green_rod')"
          />
          <img
            src="../../assets/catchFish/image/c-q.png"
            alt=""
            @click="startfishingRod('green_rod')"
            class="c-fishingRod"
            v-else-if="changeFishingRodObj.green_rod"
          />
          <img
            src="../../assets/catchFish/image/q.gif"
            alt=""
            class="gifAnimation"
            v-else
          />
          <span
            class="buyFishNum"
            v-if="!fishingRodObj.green_rod && changeFishingRodObj.green_rod"
            >{{ addBaitNumObj.green_rod }}</span
          >
        </div>
        <div>
          <img
            src="../../assets/catchFish/image/fishingRod5.png"
            alt=""
            v-if="fishingRodObj.white_rod"
            @click="fishingRod('white_rod')"
          />
          <img
            src="../../assets/catchFish/image/c-w.png"
            alt=""
            @click="startfishingRod('white_rod')"
            class="c-fishingRod"
            v-else-if="changeFishingRodObj.white_rod"
          />
          <img
            src="../../assets/catchFish/image/b.gif"
            alt=""
            class="gifAnimation"
            v-else
          />
          <span
            class="buyFishNum"
            v-if="!fishingRodObj.white_rod && changeFishingRodObj.white_rod"
            >{{ addBaitNumObj.white_rod }}</span
          >
        </div>
        <div>
          <img
            src="../../assets/catchFish/image/fishingRod8.png"
            alt=""
            v-if="fishingRodObj.diamond_rod"
            @click="fishingRod('diamond_rod')"
          />
          <img
            src="../../assets/catchFish/image/c-zs.png"
            alt=""
            @click="startfishingRod('diamond_rod')"
            class="c-fishingRod"
            v-else-if="changeFishingRodObj.diamond_rod"
          />
          <img
            src="../../assets/catchFish/image/zs.gif"
            alt=""
            class="gifAnimation"
            v-else
          />
          <span
            class="buyFishNum"
            v-if="!fishingRodObj.diamond_rod && changeFishingRodObj.diamond_rod"
            >{{ addBaitNumObj.diamond_rod }}</span
          >
        </div>
      </div>
      <div class="box-item">
        <div>
          <img
            src="../../assets/catchFish/image/fishingRod4.png"
            alt=""
            v-if="fishingRodObj.yellow_rod"
            @click="fishingRod('yellow_rod')"
          />
          <img
            src="../../assets/catchFish/image/c-y.png"
            alt=""
            @click="startfishingRod('yellow_rod')"
            class="c-fishingRod"
            v-else-if="changeFishingRodObj.yellow_rod"
          />
          <img
            src="../../assets/catchFish/image/h.gif"
            alt=""
            class="gifAnimation"
            v-else
          />
          <span
            class="buyFishNum"
            v-if="!fishingRodObj.yellow_rod && changeFishingRodObj.yellow_rod"
            >{{ addBaitNumObj.yellow_rod }}</span
          >
        </div>
        <div>
          <img
            src="../../assets/catchFish/image/fishingRod3.png"
            alt=""
            v-if="fishingRodObj.colored_rod"
            @click="fishingRod('colored_rod')"
          />
          <img
            src="../../assets/catchFish/image/c-c.png"
            alt=""
            @click="startfishingRod('colored_rod')"
            class="c-fishingRod"
            v-else-if="changeFishingRodObj.colored_rod"
          />
          <img
            src="../../assets/catchFish/image/c.gif"
            alt=""
            class="gifAnimation"
            v-else
          />
          <span
            class="buyFishNum"
            v-if="!fishingRodObj.colored_rod && changeFishingRodObj.colored_rod"
            >{{ addBaitNumObj.colored_rod }}</span
          >
        </div>
        <div>
          <img
            src="../../assets/catchFish/image/fishingRod2.png"
            alt=""
            v-if="fishingRodObj.golden_rod"
            @click="fishingRod('golden_rod')"
          />
          <img
            src="../../assets/catchFish/image/c-hj.png"
            alt=""
            @click="startfishingRod('golden_rod')"
            class="c-fishingRod"
            v-else-if="changeFishingRodObj.golden_rod"
          />
          <img
            src="../../assets/catchFish/image/hj.gif"
            alt=""
            class="gifAnimation"
            v-else
          />
          <span
            class="buyFishNum"
            v-if="!fishingRodObj.golden_rod && changeFishingRodObj.golden_rod"
            >{{ addBaitNumObj.golden_rod }}</span
          >
        </div>
      </div>
      <div class="item-bottom-left">
        <img
          src="../../assets/catchFish/image/fishingRod7.png"
          alt=""
          v-if="fishingRodObj.mythic_rod"
          @click="fishingRod('mythic_rod')"
        />
        <img
          src="../../assets/catchFish/image/c-sh.png"
          alt=""
          @click="startfishingRod('mythic_rod')"
          class="c-fishingRod"
          v-else-if="changeFishingRodObj.mythic_rod"
        />
        <img
          src="../../assets/catchFish/image/sh.gif"
          alt=""
          class="gifAnimation"
          v-else
        />
        <span
          class="buyFishNum"
          v-if="!fishingRodObj.mythic_rod && changeFishingRodObj.mythic_rod"
          >{{ addBaitNumObj.mythic_rod }}</span
        >
      </div>
      <div class="item-bottom-right">
        <img
          src="../../assets/catchFish/image/fishingRod1.png"
          alt=""
          v-if="fishingRodObj.legendary_fishing_rod"
          @click="fishingRod('legendary_fishing_rod')"
        />
        <img
          src="../../assets/catchFish/image/c-cs.png"
          alt=""
          @click="startfishingRod('legendary_fishing_rod')"
          class="c-fishingRod"
          v-else-if="changeFishingRodObj.legendary_fishing_rod"
        />
        <img
          src="../../assets/catchFish/image/cs.gif"
          alt=""
          class="gifAnimation"
          v-else
        />
        <span
          class="buyFishNum"
          v-if="!fishingRodObj.legendary_fishing_rod &&  changeFishingRodObj.legendary_fishing_rod "
          >{{ addBaitNumObj.legendary_fishing_rod }}</span
        >
      </div>
    </div>

    <div class="Bubbles">
      <img src="../../assets/catchFish/image/fish-bg.png" alt="" />
      <img
        src="../../assets/catchFish/image/fish-bg.png"
        alt=""
        style="margin-left:2px;width: 0.3rem;"
      />
      <img
        src="../../assets/catchFish/image/fish-bg.png"
        alt=""
        style="margin-left:4px;width: 0.25rem;"
      />
    </div>
    <div class="BubblesOne">
      <img src="../../assets/catchFish/image/fish-bg.png" alt="" />
    </div>
    <img
      src="../../assets/catchFish/image/fish.png"
      alt=""
      class="fishImgOne"
    />
    <img
      src="../../assets/catchFish/image/fish3.png"
      alt=""
      class="fishImgTwo"
    />
    <img
      src="../../assets/catchFish/image/fish2.png"
      alt=""
      class="fishImgThree"
    />
    <img
      src="../../assets/catchFish/image/fish1.png"
      alt=""
      class="fishImgFour"
    />

    <audio id="audio" ref="audio" autoplay >
      <source :src="musicUrl"  type="audio/wav" />
    </audio>

    <rule
      :content="dataObj['gold']['remark']"
      v-show="showRule"
      @close="showRule = false"
    />
    <fishPrize v-if="showPrize" :prize="prize" @close="reset" />

    <div class="baitBox">
      <div class="bait-num">
        <img
          src="../../assets/catchFish/image/bati.png"
          alt=""
          class="baitBox-img"
        />
        <span class="bait-num-text">{{ baitNum }}</span>
        <span class="bait-num-btn" @click="baitNumHandle">+</span>
      </div>
      <div class="bait-add">
        <div @click="abbBaitHandle" v-if="baitState">
          增加鱼饵 <span>{{ timeNum }}s</span>
        </div>
        <div v-else>出鱼中</div>
        <div class="bait-add-text"></div>
      </div>
      <div class="bait-proportion" v-show="isBaitAllBox">
        <div class="bait-pro-text">{{ baitNumAll }}/1000</div>
        <div class="bait-pro-bg"></div>
      </div>
    </div>

    <div class="maskBox" v-if="confirmUnboxing">
      <img
        src="../../assets/catchFish/image/cancel.png"
        alt=""
        class="cancelImg"
        @click="confirmUnboxing = false"
      />
      <div class="mask-content">
        <div class="content-item">
          <span class="content-title">赠送主播</span>
          <span class="carp-fish">
            <img
              src="../../assets/catchFish/image/fish-bg.png"
              alt=""
              class="carpImg-bg"
            />
            <img
              src="../../assets/catchFish/image/carp.png"
              alt=""
              class="carpImg"
            />
          </span>
          <span class="content-fish"
            >【 鲤鱼 】 <br />
            x {{ GiftNum }}
          </span>
        </div>

        <div class="content-item">
          <span class="content-title">得</span>
          <span class="carp-fish">
            <img
              src="../../assets/catchFish/image/fish-bg.png"
              alt=""
              class="carpImg-bg"
            />
            <img
              src="../../assets/catchFish/image/bati.png"
              alt=""
              class="carpImg"
            />
          </span>
          <span class="content-fish"
            >【 鱼饵 】 <br />
            x {{ GiftNum }}
          </span>
        </div>

        <div class="mask-btn">
          <div class="mask-btn-item" @click="giftAnchorHandle('one')">
            送1个 <br />
            200橙币
          </div>
          <div class="mask-btn-item" @click="giftAnchorHandle('ten')">
            送10个 <br />
            2000橙币
          </div>
          <div class="mask-btn-item" @click="giftAnchorHandle('hundred')">
            送100个<br />
            20000橙币
          </div>
          <div class="mask-btn-item" @click="giftAnchorHandle('fiveHundred')">
            送500个 <br />
            100000橙币
          </div>
        </div>
      </div>
    </div>

    <div class="addBait" v-if="addBaitBoxing">
      <div class="addBait-content">
        <div class="addBait-title">
          <img :src="fishRodImg" alt="" class="title-rod" />
          <div class="rod-type">
            <span>{{ fishRodName }}</span>
            <div>
              只能钓到：{{ fishType }}
              <img :src="fishImg" alt="" class="fish-type" />
            </div>
          </div>
        </div>
        <div class="addBait-item">
          <div class="addBait-item-bait">
            <img
              src="../../assets/catchFish/image/bati.png"
              alt=""
              class="addBait-item-bait-img"
            />
            <div>{{ baitNum }}</div>
          </div>
          <div class="addBait-item-num">
            <button @click="addBaitNum > 0 ? addBaitNum-- : '0'">-</button>
            <div>
              <span>增 加 鱼 饵</span>
              <!-- <div>{{ addBaitNum }}</div> -->
              <input
                type="text"
                v-model="addBaitNum"
                class="addBait-item-num-input"
              />
            </div>
            <button @click="addBaitNum++">+</button>
          </div>
          <div class="addBait-item-change">
            <div
              :class="{ changeActive: activeNum == 'ten' }"
              @click="changeBaitNum('ten')"
            >
              10
            </div>
            <div
              :class="{ changeActive: activeNum == 'twenty' }"
              @click="changeBaitNum('twenty')"
            >
              20
            </div>
            <div
              :class="{ changeActive: activeNum == 'fifty' }"
              @click="changeBaitNum('fifty')"
            >
              50
            </div>
            <div
              :class="{ changeActive: activeNum == 'hundred' }"
              @click="changeBaitNum('hundred')"
            >
              100
            </div>
          </div>
          <div class="addBait-btn">
            <div
              style="color: #999;border-right:1px solid #ccc;"
              @click="addBaitBoxing = false"
            >
              取消
            </div>
            <div style="color:#0374d1;" @click="putInHandle">投放</div>
          </div>
        </div>
      </div>
    </div>

    <div class="historyBox">
      <div class="history-title">近30场中奖记录：</div>
      <div v-for="(item, index) in historyArray" :key="index">
        <img :src="item" alt="" />
      </div>
    </div>

    <history
      :content="content"
      v-show="showHistory"
      @close="showHistory=false"
    />
  </div>
</template>

<script>
import "@/assets/catchFish/css/index.css";
import rule from "@/components/rule";
import fishPrize from "./fishPrize.vue";
import history from "@/components/history.vue";
import { Toast } from "vant";

import green_rod_img from "../../assets/catchFish/image/fishingRod6.png";
import countdown from "../../assets/catchFish/video/countdown.mp3";
import fishingRod from "../../assets/catchFish/video/fishingRod.mp3";
import goFishing from "../../assets/catchFish/video/goFishing.mp3";
import startPrediction from "../../assets/catchFish/video/startPrediction.mp3";
import stopPrediction from "../../assets/catchFish/video/stopPrediction.mp3";

export default {
  components: {
    fishPrize,
    rule,
    history,
  },
  data() {
    return {
      showRule: false,
      showPrize: false,
      showHistory: false,
      content:[],
      gold: 0,
      prize: {
        img: "",
        isPrize: "",
        prizeInfo:{},
      },
      baitNum: 0,
      fishingRodObj: {
        legendary_fishing_rod: true,
        golden_rod: true,
        colored_rod: true,
        yellow_rod: true,
        white_rod: true,
        green_rod: true,
        mythic_rod: true,
        diamond_rod: true,
      },
      changeFishingRodObj: {
        legendary_fishing_rod: true,
        golden_rod: true,
        colored_rod: true,
        yellow_rod: true,
        white_rod: true,
        green_rod: true,
        mythic_rod: true,
        diamond_rod: true,
      },
      addBaitNumObj: {
        legendary_fishing_rod: 0,
        golden_rod: 0,
        colored_rod: 0,
        yellow_rod: 0,
        white_rod: 0,
        green_rod: 0,
        mythic_rod: 0,
        diamond_rod: 0,
      },
      activeNum: "",
      addBaitNum: 0,
      fishType: "",
      fishImg: "",
      fishRodName: "",
      fishRodImg: "",
      // bottIcon,
      confirmUnboxing: false,
      addBaitBoxing: false,
      catchDom: "gold",
      GiftNum: "1",
      requestCompleted: true,
      baitState: true,
      num: "one",
      dataObj: {
        golden_rod: {},
        white_rod: {},
        diamond_rod: {},
        mythic_rod: {},
        legendary_fishing_rod: {},
        green_rod: {},
        colored_rod: {},
        yellow_rod: {},
        gold: {},
      },
      catchDom: "gold",
      playNum: "1",
      showEggGif: false,
      typeRod: "",
      timeNum: "",
      gameId: "",
      baitNumAll: 10,
      changeFishRodTpye: "",
      changeFishRodId: "",
      musicUrl: fishingRod,
      fishprize: "",
      isuserChange: false,
      isBaitAllBox: true,
      historyArray: [],
      videoTime:''
    };
  },
  mounted() {
    this.getInfo();
    this.startGrowth();
    this.$socket.open();
    let audioDom = document.querySelector("#audio");
    audioDom.onended = function() {
      //播放完毕，重新循环播放
      audioDom.load();
      audioDom.play();
    };  
    this.videoTime = setTimeout(() => {
      this.$refs.audio && this.$refs.audio.play();
    },500);
    // this.playMusic(fishingRod, '0.5')
  },
  beforeDestroy() {
    clearTimeout(this.videoTime);
    this.$socket.close();
  },
  watch: {
    "globalData.XPLATFORM": {
      handler(newVal) {
        newVal && this.getInfo();
      },
    },
  },
  sockets: {
    disconnect() {
      console.log("Socket 断开");
    },
    error() { 
      console.log("Socket 连接失败");
    },
    connect() {
      console.log("Socket 连接成功");
      this.$socket.emit("joinGame", { room_no: this.globalData.roomId });
      this.setDate();
      this.playMusic(fishingRod, '0.5')
    },
    fishGameMassage(data) {
      console.log("fishGameMassage", data);
      // this.setDate()
      if (data.game_status == 1) { 
        this.timeNum = data.game_time_left;
        this.gameId = data.game_id;
        this.fishprize = data.game_winning_rod;
        this.showPrize = false;
        this.baitState = true;
        this.isBaitAllBox = true;
        this.prize.isPrize = false;
        this.prize.img = "";
      } else {
          this.fishingRodObj[data.game_winning_rod] = false;
          this.changeFishingRodObj[data.game_winning_rod] = false;
          this.playMusic(goFishing,'1')
          setTimeout(() => {
            this.showPrize = true;
            this.fishingRodObj[data.game_winning_rod] = true;
            this.isuserChange = false; 
            this.addBaitNum = 0; 
            setTimeout(()=>{
              this.playMusic(startPrediction,'1')
            },3000)
          for (let key in this.fishingRodObj) {
            this.fishingRodObj[key] = true;
          }
          for (let key in this.addBaitNumObj) {
            this.addBaitNumObj[key] = 0;
          }
          for (let key in this.changeFishingRodObj) {
            this.changeFishingRodObj[key] = true;
          }
        }, 5000);
      }
    }, 
    joinGame(data) {
      console.log("加入游戏", data);
    },
    gameRecordMassage(data) {
      this.historyArray = data;
    },
    gameUser(data) {
      console.log("游戏用户", data);
      this.baitNum = data.fish_food_num;
    },
    receiveFishMessage(data) {
      console.log("出奖", data);
      this.prize.isPrize = true;
      this.prize.prizeInfo = data

      setTimeout(() => {
        this.prize.isPrize = false;
      }, 4500);
    },
    gameSettlementMessages(data) {
      console.log("系统", data);
      this.prize.img = data.gift_image;
      // this.showPrize = true;
    },
  },

  methods: {
    startGrowth() {
      setInterval(() => {
        this.baitNumAll += Math.floor(Math.random() * 100);
        document.getElementsByClassName("bait-pro-bg")[0].style.width =
          this.baitNumAll / 10 + "%";
      }, 3000);
    },

    changeBaitNum(data) {
      this.activeNum = data;
      if (data == "ten") {
        this.addBaitNum = 10;
      } else if (data == "twenty") {
        this.addBaitNum = 20;
      } else if (data == "fifty") {
        this.addBaitNum = 50;
      } else if (data == "hundred") {
        this.addBaitNum = 100;
      }
    },
    setDate() {
      setInterval(() => {
        if(this.timeNum == '30'){
          this.playMusic(fishingRod, '0.5')
        }
        if (this.timeNum > 0) {
          this.timeNum = this.timeNum - 1;
          document.getElementsByClassName("bait-add-text")[0].style.width =
            this.timeNum * 3.3 + "%";
        } else {
          this.baitState = false;
          this.baitNumAll = 6;
          this.isBaitAllBox = false;
          this.fishingRodObj[this.fishprize] = false;
        }
        if(this.timeNum == '7'){
          this.playMusic(stopPrediction,'1')
        }
        if(this.timeNum == '5'){
          this.playMusic(countdown, '1')
        } 
      }, 1000);
    }, 

    playMusic(musicType, speed){
      this.musicUrl = musicType
      this.$refs.audio.load(); // 加载音频文件
      this.$refs.audio.play(); // 播放音频文件
      this.$refs.audio.playbackRate = speed; // 播放音频文件的倍速
    },
    giftAnchorHandle(data) {
      if (data == "one") {
        this.GiftNum = 1;
      } else if (data == "ten") {
        this.GiftNum = 10;
      } else if (data == "hundred") {
        this.GiftNum = 100;
      } else if (data == "fiveHundred") {
        this.GiftNum = 500;
      }
      if (parseInt(this.gold) < parseInt(this.GiftNum * 200)) {
        return Toast.fail({ message: "余额不足", position: "bottom" });
      }

      this.$socket.emit("buyFishFood", {
        room_no: this.globalData.roomId,
        fish_food_num: this.GiftNum,
        game_id: this.gameId,
      });
      this.confirmUnboxing = false;
    },
    getInfo() {
      if (this.globalData.XPLATFORM) {
        this.$http
          .get("lottery-game/info", {
            params: { code: "green_rod" },
            headers: {
              "X-PLATFORM": this.globalData.XPLATFORM,
            },
          })
          .then((res) => {
            this.dataObj.green_rod = res.data;
            this.gold = res.data["gold"];
            this.dataObj.gold.remark = res.data.remark;
          });
        this.$http
          .get("lottery-game/info", {
            params: { code: "legendary_fishing_rod" },
            headers: {
              "X-PLATFORM": this.globalData.XPLATFORM,
            },
          })
          .then((res) => {
            this.dataObj.legendary_fishing_rod = res.data;
            this.gold = res.data["gold"];
          });
        this.$http
          .get("lottery-game/info", {
            params: { code: "golden_rod" },
            headers: {
              "X-PLATFORM": this.globalData.XPLATFORM,
            },
          })
          .then((res) => {
            this.dataObj.golden_rod = res.data;
            this.gold = res.data["gold"];
          });
        this.$http
          .get("lottery-game/info", {
            params: { code: "colored_rod" },
            headers: {
              "X-PLATFORM": this.globalData.XPLATFORM,
            },
          })
          .then((res) => {
            this.dataObj.colored_rod = res.data;
            this.gold = res.data["gold"];
          });
        this.$http
          .get("lottery-game/info", {
            params: { code: "yellow_rod" },
            headers: {
              "X-PLATFORM": this.globalData.XPLATFORM,
            },
          })
          .then((res) => {
            this.dataObj.yellow_rod = res.data;
            this.gold = res.data["gold"];
          });
        this.$http
          .get("lottery-game/info", {
            params: { code: "white_rod" },
            headers: {
              "X-PLATFORM": this.globalData.XPLATFORM,
            },
          })
          .then((res) => {
            this.dataObj.white_rod = res.data;
            this.gold = res.data["gold"];
          });
        this.$http
          .get("lottery-game/info", {
            params: { code: "mythic_rod" },
            headers: {
              "X-PLATFORM": this.globalData.XPLATFORM,
            },
          })
          .then((res) => {
            this.dataObj.mythic_rod = res.data;
            this.gold = res.data["gold"];
          });
        this.$http
          .get("lottery-game/info", {
            params: { code: "diamond_rod" },
            headers: {
              "X-PLATFORM": this.globalData.XPLATFORM,
            },
          })
          .then((res) => {
            this.dataObj.diamond_rod = res.data;
            this.gold = res.data["gold"];
          });
      }
    },
    reset() {
      this.$refs.audio.pause(); // 停止播放音频文件
      // $("#" + this.catchDom).attr("src", this.relevanceObj[this.catchDom]);
      this.showPrize = false;
      this.isShowImg = true;
      this.requestCompleted = true;
      this.getInfo();
    },
    fishingRod(type) {
      if (this.baitState == false) {
        return;
      }
      this.changeFishRodTpye = type;
      if (this.baitNum == "0") {
        this.confirmUnboxing = true;
      } else {
        this.addBaitBoxing = true;
      }

      if (type == "green_rod") {
        this.fishRodImg = green_rod_img;
        this.fishRodName = "青竿";
        this.fishType = this.dataObj[type].prize[0].remark;
        this.fishImg = this.dataObj[type].prize[0].image;
      } else {
        this.fishRodImg = this.dataObj[type].image;
        this.fishRodName = this.dataObj[type].name.slice(6);
        this.fishType = this.dataObj[type].prize[0].remark;
        this.fishImg = this.dataObj[type].prize[0].image;
      }
    },
    getData(type, dom) {
      if (!this.globalData.XPLATFORM) {
        return;
      }
      if (!this.requestCompleted) {
        return;
      }

      const that = this;
      // this.$refs.audio.load(); // 加载音频文件
      // this.$refs.audio.play(); // 播放音频文件
      // this.$refs.audio.playbackRate = 0.5; // 播放音频文件的倍速
      this.$http
        .post(
          "lottery-game/draw",
          {
            type,
            code: dom,
            room_id: that.globalData.roomId,
          },
          {
            headers: { "X-PLATFORM": this.globalData.XPLATFORM },
          }
        )
        .then((res) => {
          this.prize = res;
          setTimeout(() => {
            for (let i in this.fishingRodObj) {
              if (i == dom) {
                this.$set(this.fishingRodObj, i, true);
              }
            }
            that.showPrize = true;
            this.$refs.audio.playbackRate = 1; // 播放音频文件的倍速
          }, 1000);
        })
        .catch((e) => {
          this.reset();
        });
    },

    startfishingRod(type) {
      this.changeFishRodTpye = type;
      this.addBaitBoxing = true;
      if (type == "green_rod") {
        this.fishRodImg = green_rod_img;
        this.fishRodName = "青竿";
        this.fishType = this.dataObj[type].prize[0].remark;
        this.fishImg = this.dataObj[type].prize[0].image;
      } else {
        this.fishRodImg = this.dataObj[type].image;
        this.fishRodName = this.dataObj[type].name.slice(6);
        this.fishType = this.dataObj[type].prize[0].remark;
        this.fishImg = this.dataObj[type].prize[0].image;
      }
    },
    abbBaitHandle() {
      console.log(this.fishRodName);
      if (this.fishRodName == "") {
        this.addBaitBoxing = true;
        this.fishRodImg = green_rod_img;
        this.fishRodName = "青竿";
        this.fishType = this.dataObj[type].prize[0].remark;
        this.fishImg = this.dataObj[type].prize[0].image;
      } else {
        this.addBaitBoxing = true;
      }
    },
    putInHandle() {
      this.$socket.emit("betFishFood", {
        room_no: this.globalData.roomId,
        rod_type: this.changeFishRodTpye,
        buy_num: this.addBaitNum,
        game_id: this.gameId,
      });

      if (this.addBaitNum == 0) {
        this.confirmUnboxing = false;
      } else {
        if (this.addBaitNum > this.baitNum) {
          return Toast.fail({
            message: "鱼食不足，请购买",
            position: "bottom",
          });
        }
        this.addBaitNumObj[this.changeFishRodTpye] += parseInt(this.addBaitNum);
        this.fishingRodObj[this.changeFishRodTpye] = false;
        this.addBaitBoxing = false;
        this.isuserChange = true;
      }
    },
    determineHandle() {
      this.confirmUnboxing = false;
      // this.play(this.typeRod);
    },
    getMyHistoryHandle(){
      this.showHistory = true
      if (this.globalData.XPLATFORM) {
        this.$http
          .get("/fish-game/get-record", {
            headers: {
              "X-PLATFORM": this.globalData.XPLATFORM,
            },
          })
          .then((res) => {
          this.content = res.data
          })
      }
    },
    baitNumHandle() {
      this.confirmUnboxing = true;
    },
  },
};
</script>

<style scoped></style>
